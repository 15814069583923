@use 'src/app/variables' as *;

.radioGroupItemContainer {
  display: flex;
  align-items: center;
  gap: var(--io-spacing-2);
  width: 100%;
}

.radioGroupItem {
  background-color: var(--io-bg-main);
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid var(--io-border-dark);
  cursor: pointer;
}
.radioGroupItem:hover {
  background-color: var(--io-radio-button-bg);
}
.radioGroupItem:focus {
  outline: 2px solid var(--io-primary);
  outline-offset: 2px;
}
.radioGroupItem[data-state='checked'] {
  border: 2px solid var(--io-primary);
}
.radioGroupItem[data-disabled] {
  border-color: var(--io-border-dark);
  background-color: var(--io-radio-button-bg);
}
.radioGroupItem[data-state='checked'][disabled],
.radioGroupItem[data-state='checked'][data-disabled='true'] {
  background-color: var(--io-border-disabled);
  border-color: var(--io-border-disabled);
}

.radioGroupIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  fill: #fff;
}
.radioGroupIndicator::after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--io-primary);
}
.radioGroupIndicator[data-disabled]::after {
  background-color: var(--io-bg-main);
}

.labeledInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--io-spacing-2);
}

.labelWithIcon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--io-spacing-1);
}

.helperText {
  color: var(--text-dimmed-700, #5c646f);
  overflow-wrap: break-word;
}

.disabled {
  cursor: default;
  background-color: var(--io-radio-button-bg);
  border-color: var(--io-border-disabled);
}

.iconFrame {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--io-spacing-1);
  border-radius: 2px;
  border: 0.615px solid var(--io-border-main);
  width: 32px;
  height: 32px;
}
